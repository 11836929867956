import React, { useState } from 'react';
import {Form, Col, Row, Container, Button} from 'react-bootstrap'
import constants from '../../helpers/constants';

const AddSupplier = ({show, showAddForm, item, handleItemChange, saveEditItem, cancelEditItem}) => {
  const [validated, setValidated] = useState(false);

  const handleCheckboxChange = (e) => {
    const el = document.getElementsByClassName('itemType');
    var atLeastOneChecked=false;//at least one cb is checked
    for (let i=0; i<el.length; i++) {
        if (el[i].checked === true) {
            atLeastOneChecked=true;
        }
    }

    if (atLeastOneChecked === true) {
        for (let i=0; i<el.length; i++) {
            el[i].required = false;
        }
    } else {
        for (let i=0; i<el.length; i++) {
            el[i].required = true;
        }
    }
    handleItemChange(e);
  }

  const handleSubmit = (event) => {

    const el = document.getElementsByClassName('itemType');
    var atLeastOneChecked=false;//at least one cb is checked
    for (let i=0; i<el.length; i++) {
        if (el[i].checked === true) {
            atLeastOneChecked=true;
        }
    }

    if (atLeastOneChecked === true) {
        for (let i=0; i<el.length; i++) {
            el[i].required = false;
        }
    } else {
        for (let i=0; i<el.length; i++) {
            el[i].required = true;
        }
    }

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
      return;
    }

    event.preventDefault();
    saveEditItem();
  };

  const cancelAddSupplier =(e) => {
    setValidated(false);
    cancelEditItem();
  }

  return (
    <>
      
      { 
        show &&
        <Container fluid>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {!showAddForm &&
              <Form.Group as={Row} controlId="formHorizontalEmail">
                <Form.Label column sm={2}>
                  ID
                </Form.Label>
                <Col sm={10}>
                  <Form.Control 
                    required
                    data-property="id" 
                    type="text" 
                    placeholder="" 
                    value={item['id']} 
                    readOnly={true}
                    />
                </Col>
              </Form.Group>
            }
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={2}>
                Supplier Login
              </Form.Label>
              <Col sm={10}>
                <Form.Control 
                  required
                  data-property="supplierLogin" 
                  type="text" 
                  placeholder="Please enter supplier Login" 
                  value={item['supplierLogin']} 
                  onChange={handleItemChange} 
                  />
                <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Nicename
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property={'nicename'} 
                type="text" 
                placeholder={'Please enter nicename'} 
                value={item['nicename']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Phone
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property={'phone'} 
                type="text" 
                placeholder={'phone'} 
                value={item['phone']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Email
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property={'email'} 
                type="email" 
                placeholder={'email'} 
                value={item['email']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter a valid email address</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">

            <Form.Label column sm={2}>
              Address 1
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property={'address1'} 
                type="text" 
                placeholder={'address1'} 
                value={item['address1']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">

            <Form.Label column sm={2}>
              Address 2
            </Form.Label>
            <Col sm={10}>
              <Form.Control 
                required
                data-property={'address2'} 
                type="text" 
                placeholder={'address2'} 
                value={item['address2']} 
                onChange={handleItemChange} 
                />
              <Form.Control.Feedback type="invalid">Please enter a value</Form.Control.Feedback>
            </Col>
          </Form.Group>
          <Form.Group as={Row} controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Allowed Views
            </Form.Label>
            <Col sm={10}>
              
                <Form.Check
                  inline
                  required
                >
                  <Row>
                    {
                      constants.ITEM_TYPES.map((type) => {
                        let isTypeSelected = false;
                        let views = [];
                        if (Array.isArray(item.allowedViews)) {
                          views = item.allowedViews;
                        } else {
                          views = item.allowedViews ? Array.from(JSON.parse(item.allowedViews)) : [];
                        }
                        for (let index = 0; index < views.length; index++) {
                          const view = views[index];
                          if (view === type) {
                            isTypeSelected = true;
                            break;
                          }
                        }
                        
                        return (

                          <Col sm={2}>
                            <Form.Check.Input 
                              data-id='allowedViews'
                              type="checkbox" 
                              className="itemType"
                              data-checked-value={type}
                              checked={isTypeSelected}
                              onChange={handleCheckboxChange} 
                            />
                            <Form.Check.Label>{`${type}`}</Form.Check.Label>
                          </Col>
                        )
                      })
                    }
                    <Form.Control.Feedback type="invalid">Please choose one item type</Form.Control.Feedback>
                  </Row>
                </Form.Check>
            </Col>
          </Form.Group>
          <Button className="mr-1" type="submit">Save</Button>
          <Button variant="danger" onClick={cancelAddSupplier}>Cancel</Button>
          </Form>
        </Container>
      } 
    </>
  )
}

export default AddSupplier