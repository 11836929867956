import React, {useState, useEffect} from "react"
import Layout from "../../components/layout"
import {Container, Table, Button, Row, Col} from 'react-bootstrap'
import SEO from "../../components/seo";
import Loader from '../../components/loading';
import constants from '../../helpers/constants';
import StandardModal from '../../components/modal';
import * as jwtDecode from 'jwt-decode'
import LocalStorage from "../../components/localStorage"
import Fetch from "../../helpers/fetch";
import EditSupplier from '../../components/suppliers/editSupplier'
import AddSupplier from '../../components/suppliers/addSupplier'

const Suppliers = () => {
  // const currentPage = useRef();
  const emptyEditItem = {
    id: '',
    supplierLogin: '',
    nicename: '',
    phone: '',
    email: '',
    address1:'',
    address2:'',
    allowedViews:[],
  }
  const [showLoader, setShowLoader] = useState(false);
  const [supplierArray, setSupplierArray] = useState([]);
  // const [pagination, setPagination] = useState({});

  const [supplierId, setSupplierId] = useState(0);
  const [editItem, setEditItem] = useState(emptyEditItem);
  const [showEditSupplier, setShowEditSupplier] = useState(false);
  const [showAddSupplier, setShowAddSupplier] = useState(false);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [allowedViews, setAllowedViews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);


  const getSupplierId = () => {
    if (jwtDecode(LocalStorage.get('jwt')) && jwtDecode(LocalStorage.get('jwt')).id) {
      setSupplierId(jwtDecode(LocalStorage.get('jwt')).id);
    }
  }

  const getSuppliers = (page = 1, limit = 25) => {
    setShowLoader(true);
    const pageNumber = page > 0 ? page : 1;
    const offset = (pageNumber-1) * limit

    Fetch.get(`${constants.BACKEND_URL}/suppliers/${offset}/${limit}`)
    .then((data) => {
      setSupplierArray(data.data)
      setShowLoader(false);
    })
  }

  useEffect(() => {
    getSupplierId();
    getSuppliers();
  },[])

  const handleDeleteClick = (e) => {
    setDeleteId(e.target.dataset.deleteId);
    setShowModal(true)
  }

  const handleClick = (e) => {
    setEditItem(JSON.parse(e.target.dataset.value));
    setShowEditSupplier(true);
    setShowSummaryTable(false);
  }

  const handleAddSupplierClick = () => {
    setShowAddSupplier(true);
    setShowSummaryTable(false);
    setEditItem(emptyEditItem);
  }

  const handleItemChange = (e) => {
    const changedProperty = {}
    if (e.target.dataset.id === 'allowedViews') {
      let tempAllowedViews = {};
      // if(showAddSupplier && editItem && editItem.allowedViews) tempAllowedViews = JSON.parse(JSON.stringify(allowedViews));
      // if(showEditSupplier && editItem && editItem.allowedViews) tempAllowedViews = JSON.parse(editItem.allowedViews)
      if (Array.isArray(editItem.allowedViews)) {
        tempAllowedViews = JSON.parse(JSON.stringify(editItem.allowedViews));
      } else {
        tempAllowedViews = JSON.parse(editItem.allowedViews)
      }
      
      if (e.target.checked) {
        tempAllowedViews.push(e.target.dataset.checkedValue);
      } else {
        tempAllowedViews = tempAllowedViews.filter(x => {
          return x !== e.target.dataset.checkedValue;
        })
      }
      
      setAllowedViews(showAddSupplier ? tempAllowedViews : JSON.stringify(tempAllowedViews));
    } else {
      changedProperty[e.target.dataset.property] = e.target.value;
      setEditItem({...editItem, ...changedProperty});
    }
  }
  
  useEffect(() => {
    setEditItem({...editItem, allowedViews })
  },[allowedViews])

  const saveEditItem= (e) => {
    const url = `${constants.BACKEND_URL}/suppliers`
    if(showAddSupplier) {
      Fetch.post(url, JSON.stringify(editItem))
      .then((data) => {
        // setSupplierArray(data.data)
        setShowSummaryTable(true);
        getSuppliers();
      })

    } else {
      Fetch.put(url, JSON.stringify(editItem))
      .then((data) => {
        setSupplierArray(data.data)
        setShowSummaryTable(true);
      })

    }
    setAllowedViews([]);
    setShowEditSupplier(false);
    setShowAddSupplier(false);
  }

  const cancelEditItem = () => {
    setShowSummaryTable(true);
    setShowEditSupplier(false);
    setShowAddSupplier(false);
    setAllowedViews([]);
    setEditItem(emptyEditItem);
  }

  const handleModalClose = () => {
    setShowModal(false);
  }

  const handleModalConfirm = () => {
    Fetch.delete(`${constants.BACKEND_URL}/suppliers/${deleteId}`).then((data) => {
      setSupplierArray(data.data)
    })
    setShowModal(false);
  }
  
  return (
    <Layout pageInfo={{ pageName: "suppliers" }}>
        <Loader show={showLoader}></Loader>
        <SEO title="Suppliers page" />
        <Row>
          <Col className="text-center">
            <h5>Supplier Summary</h5>
          </Col>
        </Row>
        { showSummaryTable && 
        <Container fluid className="text-center" >
          <Row className="mb-3">
            <Col lg={2} md="4" xs="6" className="text-left">
              <Button onClick={handleAddSupplierClick}>Add Supplier</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Supplier ID</th>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Email Address</th>
                    <th>Address 1</th>
                    <th>Address 2</th>
                    <th>Allowed Views</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {supplierArray && Array.isArray(supplierArray) ? 
                    supplierArray.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.nicename}</td>
                          <td>{item.phone}</td>
                          <td>{item.email}</td>
                          <td>{item.address1}</td>
                          <td>{item.address2}</td>
                          <td>{item.allowedViews}</td>
                          <td>
                            <Button className="mr-1" data-value={JSON.stringify(item)} onClick={handleClick}>Edit</Button>
                            <Button variant="danger" data-delete-id={item.id} onClick={handleDeleteClick}>Delete</Button>
                          </td>
                        </tr>
                      )
                    }) : ''
                  }
                </tbody>
              </Table>  
            </Col>
          </Row>
          
        </Container>
        }
        <EditSupplier 
          show={showEditSupplier} 
          item={editItem} 
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
        ></EditSupplier>
        <AddSupplier 
          show={showAddSupplier} 
          item={editItem} 
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
        ></AddSupplier>

        <StandardModal 
          onConfirm={handleModalConfirm}
          onClose={handleModalClose} 
          show={showModal}
          title="Delete Supplier"
          body={`Are you sure you want to delete supplier?`}
        >
        </StandardModal>
    </Layout>
  )
}

export default Suppliers